<template>
    <main-layout itemMenuActive="29" tituloMenu="Condensado Recuperación">
        <div class="container">
            <br />
            <form v-on:submit.prevent="handleSubmit">
                <div class="columns is-mobile is-multiline is-centered">
                    <div class="column is-12">
                        <div class="card">
                            <div class="card-content">
                                <b-field grouped label="Condensado de Recuperación">
                                </b-field>
                                <b-field label="Fecha">
                                    <!--<b-datepicker type="month" placeholder="" icon="calendar-today" trap-focus
                                        v-model="fecha">
                                    </b-datepicker>-->
                                    <b-datepicker placeholder="" icon="calendar-today" trap-focus
                                        v-model="fecha" range :max-date="maxDate" :min-date="minDate">
                                    </b-datepicker>
                                    <b-button native-type="submit" type="is-info"
                                        style="margin-left: 0.5rem">Consultar</b-button>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-6" v-if="series.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <TableCard messageEmpty="Sin datos para procesar." :columns="columns" :info="dataTable"
                                detailKey="counter" :tableConfig="tableConfig" :haveFilter="false"></TableCard>
                        </div>
                    </div>
                </div>
                <div class="column is-6" v-if="series.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <span>Transacciones Correctas VS Fallidas</span>
                            <div id="chart">
                                <apexchart type="pie" width="380" :options="chartOptions" :series="series"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-10" v-if="seriesErrores.length > 0">
                    <div class="card">
                        <div class="card-content">
                            <span>Errores en Transacciones Fallidas </span>
                            <div id="chart">
                                <apexchart type="pie" heigth="680" :options="chartOptionsErrores" :series="seriesErrores"></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="true"></b-loading>
        </div>
    </main-layout>
</template>
<script>
import MainLayout from "@/components/Layout/MainLayout";
import { mapMutations, mapActions, mapState } from "vuex";
import apexchart from "vue-apexcharts";
//import { formatData } from "../../assets/config/utilities";
import { COLUMNS_CONSOLIDADO } from "../../assets/config/constants/TableConstants";
export default {
    name: "RLogin",
    components: {
        MainLayout, apexchart,
        TableCard: () => import("../../components/cards/TableCard")
    },
    data() {
        const today = new Date()
        return {
            series: [],
            isLoading: false,
            locale: "en-CA",
            maxDate: new Date(),
            minDate: new Date(today.getFullYear()-1, today.getMonth(), today.getDay()),
            isCardModalActive: false,
            fecha: [],
            dataTable: [],
            tableConfig: {
                isPaginated: true,
                isPaginationRounded: true,
                perPage: 10,
                backendSorting: true,
                isDetailed: false,
                isPaginatedInput: true,
            },
            chartOptions: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: ["Correctas", "Fallidas - Funcionales","Fallidas - Técnicas"],
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            seriesErrores: [],
            chartOptionsErrores: {
                chart: {
                    width: 380,
                    type: "pie",
                },
                labels: [],
                legend: {
                    width: 550,
                    position: "right",
                    offsetY: 0,
                    fontSize: "11px",
                    fontWeight: 400,
                    markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    fillColors: undefined,
                    radius: 12,
                    offsetX: 0,
                    offsetY: 0,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            columns: COLUMNS_CONSOLIDADO
        };
    },
    computed: {
        ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
    },
    mounted() {
        this.$emit("tituloTransaccion", "Condensado Autenticación");
        this.nombreTituloMenu("Condensado Autenticación");
    },
    methods: {
        handleSubmit() {
            this.series.length = 0;
            this.seriesErrores.length = 0;
            this.chartOptionsErrores.labels.length = 0;
            this.isLoading = true;
            const options = {
                method: "post",
                uri: "vulogs/recuperarCondensado",
                data: {
                    FechaInicial: this.fecha[0].length === 0 ? new Date() : this.fecha[0],
                    FechaFinal: this.fecha[1].length === 0 ? new Date() : this.fecha[1],
                },
            };
            this._axios(options)
                .then((response) => {
                    if(response.data.cant_fallidas_fun + response.data.cant_fallidas_tec + response.data.cant_exitosas > 0){
                        this.dataTable = [
                            { 'nombre': 'TX Recuperación Exitosas', 'cantidad': (response.data.cant_exitosas).toLocaleString('eS-CO')},
                            { 'nombre': 'TX Recuperación Fallidas - Funcionales', 'cantidad': (response.data.cant_fallidas_fun).toLocaleString('eS-CO') },
                            { 'nombre': 'TX Recuperación Fallidas - Técnicas', 'cantidad': (response.data.cant_fallidas_tec).toLocaleString('eS-CO') },
                            { 'nombre': 'TX Total', 'cantidad': (response.data.cant_fallidas_tec + response.data.cant_fallidas_fun + response.data.cant_exitosas).toLocaleString('eS-CO') }
                        ];
                        if(response.data.cant_exitosas > 0 || response.data.cant_fallidas_fun > 0 || response.data.cant_fallidas_tec > 0){
                            this.series.push(response.data.cant_exitosas);
                            this.series.push(response.data.cant_fallidas_fun);
                            this.series.push(response.data.cant_fallidas_tec);
                        }
                    }

                    response.data.datos.forEach((item) => {
                        this.seriesErrores.push(item.cantidad);
                        this.chartOptionsErrores.labels.push(item.error);
                    })
                })
                .catch((error) => {
                    this.isLoading = false;
                    console.log(error);
                })
                .finally(() => this.cancelarLoading());
        },
        cancelarLoading() {
            setTimeout(() => {
                this.isLoading = false;
            }, 1500);
        },
        ...mapMutations(["nombreTituloMenu"]),
        ...mapActions(["_axios"]),
    }
}
</script>
